import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useLocalStorage from "@hooks/useLocalStorage";
// Post View
var TRACKING_POST_PARAMS = ['product_position', 'listing_type', 'page_number', 'is_rec', 'bigGallView', 'logged_in', 'section_type'];

// Listing
var TRACKING_LISTING_PARAMS = ['referral_location', 'browse_variant', 'variant_value'];
var ELIGIBILITY_PARAMS = ['reset_eligibility'];
var PARAM_CATEGORIES = {
  post: TRACKING_POST_PARAMS,
  listing: TRACKING_LISTING_PARAMS,
  eligibility: ELIGIBILITY_PARAMS,
  all: [].concat(TRACKING_POST_PARAMS, TRACKING_LISTING_PARAMS, ELIGIBILITY_PARAMS)
};
var excludedPaths = ["/report/", "/dashboard/", "/admin/"];
var shouldNavigate = __IS_CSR__ ? !excludedPaths.some(function (path) {
  return window.location.pathname.indexOf(path) !== -1;
}) : true;
var getParamsForCategories = function getParamsForCategories(categories) {
  var categoryArray = Array.isArray(categories) ? categories : [categories];
  if (categoryArray.length === 0 || categoryArray.includes('all')) {
    return PARAM_CATEGORIES.all;
  }
  var uniqueParams = new Set();
  categoryArray.forEach(function (category) {
    if (PARAM_CATEGORIES[category]) {
      PARAM_CATEGORIES[category].forEach(function (param) {
        return uniqueParams.add(param);
      });
    }
  });
  return Array.from(uniqueParams);
};
export var getAndClearTrackingReferralParams = function getAndClearTrackingReferralParams() {
  var clearItems = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  var categories = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ['all'];
  var trackingReferralParams = {};
  var _useLocalStorage = useLocalStorage(),
    getLocalStorage = _useLocalStorage.getLocalStorage,
    removeLocalStorage = _useLocalStorage.removeLocalStorage;
  if (__IS_SSR__) {
    return trackingReferralParams;
  }
  var categoryArray = Array.isArray(categories) ? categories : [categories];
  var paramsToProcess = getParamsForCategories(categoryArray);
  paramsToProcess.forEach(function (key) {
    var value = getLocalStorage(key);
    if (value) {
      trackingReferralParams[key] = value;
      if (clearItems) {
        removeLocalStorage(key);
      }
    }
  });
  return trackingReferralParams;
};
var useHashToLocalStoreEvent = function useHashToLocalStoreEvent() {
  var _window2, _window2$location;
  var categories = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ['all'];
  if (__IS_SSR__) {
    return;
  }
  var location = useLocation();
  var navigate = useNavigate();
  var categoryArray = Array.isArray(categories) ? categories : [categories];
  useEffect(function () {
    var _window;
    if (!navigate || !((_window = window) !== null && _window !== void 0 && _window.location)) return;
    var handleHashChange = function handleHashChange() {
      var _useLocalStorage2 = useLocalStorage(),
        setLocalStorage = _useLocalStorage2.setLocalStorage;
      if (location.hash) {
        var hash = location.hash.slice(1); // Remove the leading '#'
        var params = new URLSearchParams(hash);
        var paramsToProcess = getParamsForCategories(categoryArray);
        paramsToProcess.forEach(function (key) {
          var value = params.get(key);
          if (value) {
            setLocalStorage(key, value);
          }
        });

        // Remove the hash from the URL without triggering a page reload
        // also include the query string if any
        if (shouldNavigate) {
          navigate(window.location.pathname + window.location.search, {
            replace: true
          });
        }
      }
    };
    handleHashChange(); // Call once on mount and when location changes

    // Cleanup function
    return function () {
      // No cleanup needed for this hook
    };
  }, [(_window2 = window) === null || _window2 === void 0 ? void 0 : (_window2$location = _window2.location) === null || _window2$location === void 0 ? void 0 : _window2$location.href, navigate, JSON.stringify(categoryArray)]);
};
export default useHashToLocalStoreEvent;