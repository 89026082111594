import {useEffect} from "react";
import {
    BUILD_VERSION,
    SELECTED_ENV,
    SENTRY_CLIENT_DSN,
    SENTRY_DEBUG,
    SENTRY_SAMPLE_RATE,
    SENTRY_TRACE_SAMPLE_RATE
} from "@globalConfig";
import {loadSentryClient} from "../../utils/asyncHelpers";
import useDelayRender from "./useDelayRender";

/**
 * Dynamically imports and initializes Sentry module.
 *
 * This function asynchronously imports the Sentry module from `@sentry/react`
 * and initializes it. It allows for lazy loading of the module to optimize
 * load times and bundle sizes.
 *
 * @param {typeof import('@sentry/react')} Sentry - A promise that resolves to the Sentry module.
 * @returns {void}
 */
function initSentry(Sentry = null) {

    if (!Sentry) return;
    Sentry.init({
        beforeSend: (event, hint) => {
            // see all errors, what you wants.
            // using console.log(hint.originalException)

            // for example, not send when error code 404 when using axios
            const {response} = hint?.originalException || {};
            let msg = '';
            const eMsg = hint?.originalException?.message || "";
            const eJson = JSON.stringify(hint?.originalException || {});
            const eHint = JSON.stringify(hint || {});
            const responseJson = JSON.stringify(response || {});
            msg = `${eMsg}-${eJson}-${eHint}-${responseJson}`.toLowerCase();

            // We ignore 404 errors
            if (response?.status === 404) {
                return null
            }
            // We ignore network errors
            if (msg.includes("network error")) {
                return null
            }
            if (msg.includes("null is not an object")) {
                return null
            }
            if (msg.includes("webpush/moe_webSdk")) {
                return null
            }
            if (msg.includes("Cannot read properties of null (reading 'removeItem')")) {
                return null
            }
            if (msg.includes("Requesting device orientation access requires a user gesture to prompt")) {
                return null
            }
            if (msg.includes("Cannot read properties of null (reading 'children')")) {
                return null
            }
            if (msg.includes("IndexSizeError")) {
                return null
            }
            if (msg.includes("this.loadSrc is not a function")) {
                // Sentry: https://syarah.sentry.io/issues/4740431688/events/17f0926290a1463e90342b0d9cb6a5e2/
                return null
            }
            if (msg.includes("NetworkError: Load failed")) {
                // Sentry: https://syarah.sentry.io/issues/4874580277/events/17055a34e3b148b78a915005a076d775/
                return null
            }
            if (msg.includes("Non-Error promise rejection captured with value: false")) {
                // Sentry: https://syarah.sentry.io/issues/4722766360/events/1042fa7a73e04161b75599c825e3b29c/
                return null
            }
            if (msg.includes("scrollReadRandom(...) is not a function")) {
                // Sentry: https://syarah.sentry.io/issues/4729642666/events/514f91df02264e58a9ed0a79833da669/
                return null
            }
            if (msg.includes("Requesting device orientation access requires a user gesture to prompt")) {
                // Sentry: https://syarah.sentry.io/issues/4723720241/events/e0e52b91076c4d859f80dcba2caa5c6a/
                return null
            }
            if (msg.includes("The Google Maps JavaScript API could not load")) {
                // Sentry: https://syarah.sentry.io/issues/5000570617/events/fc5e728471ab4d6f9b1a16cfa5bc74f5/
                return null
            }
            if (msg.includes("Non-Error promise rejection captured with value: Cannot read property 'setPageIdValue' of undefined")) {
                // Sentry: https://syarah.sentry.io/issues/4874651670/events/4f28bdea85594d098804882c7c7ea6e8/
                return null
            }
            if (msg.includes("Cannot read properties of null (reading 'children')")) {
                // Sentry: https://syarah.sentry.io/issues/4738575967/events/3391d858ddd1433c99a2506a26827907/
                return null
            }
            if (msg.includes("Cannot read property '__transferData' of null")) {
                // Sentry: https://syarah.sentry.io/issues/5057982587/events/14f85c77ce604f8f9a361134a8511386/
                return null
            }
            if (msg.includes("Non-Error promise rejection captured with value: Cannot set property 'vt' of undefined")) {
                // Sentry: https://syarah.sentry.io/issues/4874651652/events/0c3417d14d804524baebce27f7f4b7bc/
                return null
            }
            if (msg.includes("undefined is not an object (evaluating 'a.L')")) {
                // Sentry: https://syarah.sentry.io/issues/5063762618/events/b8c24c4b8b374d57b1f006e2488a7bb8/
                return null
            }
            if (msg.includes("Unexpected token '<")) {
                // Sentry: https://syarah.sentry.io/issues/4723008044/events/214f1f461b31404485ca6d6e8c069421/
                return null
            }
            if (msg.includes("TimeoutError: Transaction timed out due to inactivity")) {
                // Sentry: https://syarah.sentry.io/issues/4725348225/events/4fa5ad9ac96b4ce48e83f8c27fc37019/
                return null
            }
            if (msg.includes("Cannot set properties of null (setting 'data')")) {
                // Sentry: https://syarah.sentry.io/issues/4725348225/events/4fa5ad9ac96b4ce48e83f8c27fc37019/
                return null
            }
            if (msg.includes("Failed to execute 'transaction' on 'IDBDatabase': One of the specified object stores was not found.")) {
                // Sentry: https://syarah.sentry.io/issues/4924550469/events/1d9394653ea94f56bd1d1be15e1217b2/
                return null
            }
            if (msg.includes("Cannot redefine property: websre")) {
                // Sentry: https://syarah.sentry.io/issues/4820644879/events/89bc1be22fa748629c0da9d9684cbb34/
                return null
            }
            if (msg.includes("AbortError: The operation was aborted.")) {
                // Sentry: https://syarah.sentry.io/issues/4723140064/events/6516f23c308e40e780cae38739a898ad/
                return null
            }
            if (msg.includes("Could not load \"controls\".")) {
                // Sentry: https://syarah.sentry.io/issues/4723654264/events/d4483941a23b4e8eab7670a2ff923cde/
                return null
            }
            if (msg.includes("The index is not in the allowed range.")) {
                // Sentry: https://syarah.sentry.io/issues/4832877841/events/c3c872d44e17487b9cde810da5b00dbd/
                return null
            }
            if (msg.includes("Cannot read properties of null (reading 'style')")) {
                // Sentry: https://syarah.sentry.io/issues/6275862397
                return null
            }

            return event
        },
        dsn: SENTRY_CLIENT_DSN,
        tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,
        sampleRate: SENTRY_SAMPLE_RATE,
        environment: SELECTED_ENV,
        release: BUILD_VERSION,
        debug: SENTRY_DEBUG,
        integrations: [
            Sentry.browserTracingIntegration(),
        ],
    });
}

export default function useSentry() {
    const isDone = useDelayRender();

    useEffect(() => {
        if (!SENTRY_CLIENT_DSN || !isDone) return;

        loadSentryClient().then(initSentry);

    }, [isDone]);
}
