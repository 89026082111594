import useHelpers from "@hooks/useHelpers";
import { HEADLESS_CMS_IMAGE_URL } from "@globalConfig";
export function useCMS() {
  var _useHelpers = useHelpers(),
    createUrl = _useHelpers.createUrl;
  var urlHelper = function urlHelper(url) {
    if (!url) return null;
    if (url.startsWith('http')) return url;
    return createUrl(url);
  };
  var imageUrl = function imageUrl(cmsImage) {
    var url = cmsImage.url;
    if (!url) return null;
    if (url.startsWith('http')) return url;
    return "".concat(HEADLESS_CMS_IMAGE_URL).concat(url);
  };
  return {
    urlHelper: urlHelper,
    imageUrl: imageUrl
  };
}