import {Component, useEffect, useRef} from "react";
import {createPortal} from "react-dom";
import stl from "./SharedPopUp.module.css";

const PopupBody = ({children, oowh}) => {
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current) {
            ref.current.style.minHeight = `${ref.current.scrollHeight}px`;
        }
    }, [ref])
    return (
        <div
            ref={ref}
            className={[
                stl.genralModalbody,
                oowh && stl.oowhModalBody,
            ].join(" ")}
        >
            <div>{children}</div>
        </div>
    )
}

class SharedPopUp extends Component {
    constructor(props) {
        super(props);
        this.canUseDOM = __IS_CSR__;
        this.state = {
            modalClassName: this.props.className ? this.props.className : undefined,
        };
    }

    renderPopUpHTML() {
        this.props.show &&
        (document.body.style.overflow = this.props.show ? "hidden" : "");
        return (
            <>
                {this.props.show && (
                    <div
                        className={[
                            stl.genralPopUp,
                            stl.showGenralPopUp,
                            this.state.modalClassName,
                        ].join(" ")}
                        onClick={(e) => {
                            if (e.target === e.currentTarget) {
                                this.props.onClose();
                                document.body.style.overflow = "";
                            }
                        }}
                    >
                        <div
                            style={{maxWidth: this.props.maxWidth ?? "100%"}}
                            className={[
                                stl.genralPopUpContent,
                                this.props.oowh && stl.changeMinHeight,
                            ].join(" ")}
                        >
                            {!this.props.hideCloseBtn && (
                                <span
                                    className={stl.closeGenralPopUp}
                                    onClick={() => {
                                        this.props.onClose();
                                        document.body.style.overflow = "";
                                    }}
                                ></span>
                            )}
                            <PopupBody oowh={this.props.oowh}>{this.props.children}</PopupBody>
                        </div>
                    </div>
                )}
            </>
        );
    }

    render() {
        return this.canUseDOM ? (
            createPortal(
                this.renderPopUpHTML(),
                document.getElementById("BodyPortal")
            )
        ) : (
            <></>
        );
    }
}

export default SharedPopUp;
