import _objectSpread from "/usr/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { getUniversalCookieValue } from "@/utils/GlobalJavascriptFunction";
import { SELECTED_ENV } from "@globalConfig";
import { pushEventToQueue } from "@hooks/useLocalStorageEventConsumer";
import { toastService } from "@/common/events/Toast";
export var freshWorksEvents = {
  browse_cars: ["category_path", "browse_variant"],
  car_view: ["post_id", "is_finance", "inventory_type", "post_make", "post_model", "post_trim", "post_year", "post_condition", "post_exterior_color", "post_fuel", "post_transmission", "post_cabin", "post_mileage", "post_drivetrain", "post_engine_size", "post_price", "post_url", "marketing_theme"],
  online_contact: ["contact_type", "contact_location", "post_id"],
  search: ["search_query"],
  filter_search: ["filter_name", "filter_value"],
  inspection_view: ["post_id"],
  usp_view: ["post_id"],
  book_car: ["post_id", "is_finance", "inventory_type", "post_make", "post_model", "post_trim", "post_year", "post_condition", "post_exterior_color", "post_price", "marketing_theme"],
  checkout: ["booking_type", "post_id", "inventory_type", "post_make", "post_model", "post_trim", "post_year", "post_condition", "post_exterior_color", "post_price"],
  cash_form_submit: ["post_id", "discount_code", "inventory_type", "post_make", "post_model", "post_trim", "post_year", "post_condition", "post_exterior_color", "post_price", "marketing_theme"],
  cash_order_submit: ["post_id", "order_id", "discount_code", "payment_status", "inventory_type", "post_make", "post_model", "post_trim", "post_year", "post_condition", "post_exterior_color", "post_price", "marketing_theme"],
  finance_form_submit: ["post_id", "post_price", "inventory_type", "post_make", "post_model", "post_trim", "post_year", "post_condition", "post_exterior_color"],
  finance_order_submit: ["post_id", "order_id", "payment_status", "inventory_type", "post_make", "post_model", "post_trim", "post_year", "post_condition", "post_exterior_color", "post_price"],
  car_share: ["post_id"],
  add_favorite: ["post_id"],
  user_login: ["user_login_status", "ui_language"],
  user_signup: ["user_signup_status", "ui_language"]
};
var mapEventsToFreshWorks = {
  view_post: "car_view",
  post_share: "car_share",
  view_inspection: "inspection_view"
};
var mapDataToFreshWorks = {
  inventory_type_string: "inventory_type"
};
export function handleFreshworkEvent(event, data) {
  if (!getUniversalCookieValue(null, "userId")) return;
  var dataNew = JSON.parse(JSON.stringify(data));
  var newEvent = event in mapEventsToFreshWorks ? mapEventsToFreshWorks[event] : event;
  for (var key in mapDataToFreshWorks) {
    if (dataNew[key]) {
      dataNew[mapDataToFreshWorks[key]] = dataNew[key];
      delete dataNew[key];
    }
  }
  if (freshWorksEvents[newEvent] === undefined) {
    return;
  }
  var params = prepareEventParams(freshWorksEvents, newEvent, dataNew);
  toastService.showToast(params, newEvent, "freshwork");
  console.log('freshworks ' + newEvent, JSON.stringify(params));
  pushEventToQueue({
    eventName: newEvent,
    params: _objectSpread(_objectSpread({}, params), {}, {
      event_name: newEvent,
      environment: SELECTED_ENV,
      platform: "web"
    })
  });
}
export function prepareEventParams(allowedEvents, event, data) {
  var params = {};
  allowedEvents[event].forEach(function (element) {
    if (data[element] !== undefined || typeof data[element] === "boolean") {
      var _data$element;
      params[element] = (_data$element = data[element]) !== null && _data$element !== void 0 ? _data$element : "";
    }
  });
  return params;
}