import stl from "./CardHeader.module.css";
import DiscountLabel from "@/components/SearchComponent/UnbxdComponents/UnbxdCard/DiscountLabel/DiscountLabel";
import MarkitingLabel from "@/components/SearchComponent/UnbxdComponents/UnbxdCard/MarkitingLabel/MarkitingLabel";
import AddToFavoriteButton from "@/common/HOCs/AddToFavorite/AddToFavoriteButton";
import ProgressiveImage from "@componentsShared/ProgressiveImage";
import { thumbUrl } from "@/utils/imagesHelpers";
import { useTranslation } from "@i18n";
import useIsMobile from "@hooks/useIsMobile";
import {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { useRef } from "react";
import {checkFeatureFlag} from "@/common/config/websiteConfig";
import useIsIOS from "@hooks/useIsIOS";
import {useLang} from "@/common/Contexts/LanguageContext";
import {SHOW_DEBUG_INFO} from "@globalConfig";


const IOS_SWIPE_ENABLED = checkFeatureFlag('FF_ENABLE_MULTI_LIST_GALLERY_IOS_SWIPE')


const CardHeader = ({
  post,
  rank,
  isEager,
  isCustomDesign,
  hideFavIcon,
  hideMarkitingLabel,
  hideDiscountLabel,
  TheImage = null,
  HasImageSlider = false,
  to_link,
  slideOnlyOnArr = false,
  isAnchor
}) => {
  const { t } = useTranslation(["web_search"]);
  const isMobile = useIsMobile();
  const isIos = useIsIOS();
  const lang = useLang();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showAllImages, setShowAllImages] = useState(true);
  const [openGalleryInPost, setOpenGalleryInPost] = useState(false);
  const Tag = isAnchor ? "a" : Link;
  const LinkType = isAnchor ? "href" : "to";
  const [scrollDirection, setScrollDirection] = useState(null);
    const startX = useRef(0);
    const startY = useRef(0);
    const currentX = useRef(0);
    const currentY = useRef(0);
    const isSwiping = useRef(false);

  const isSwipeEnabled = IOS_SWIPE_ENABLED && isIos || !isIos;


    useEffect(() => {
    if (selectedIndex !== 0) {
      setShowAllImages(true);
    }
  }, [selectedIndex]);

    const handleTouchStart = (event) => {
        if (!isSwipeEnabled) return;
        startX.current = event.touches[0].clientX;
        startY.current = event.touches[0].clientY;
        isSwiping.current = true;
    };

    const handleTouchMove = (event) => {
        if (!isSwipeEnabled) return;
        if (!isSwiping.current) return;
        currentX.current = event.touches[0].clientX;
        currentY.current = event.touches[0].clientY;

        const deltaX = Math.abs(startX.current - currentX.current);
        const deltaY = Math.abs(startY.current - currentY.current);


        if ((deltaX > (deltaY * 2))) {
            setScrollDirection('horizontal');
        } else {
            setScrollDirection('vertical');
        }
    };

    const handleTouchEnd = () => {
        if (!isSwipeEnabled || !isSwiping.current) return;

        const deltaX = startX.current - currentX.current;
        const threshold = 50;

        if ((lang === "ar" && deltaX < -threshold) || (lang === "en" && deltaX > threshold)) {
            doSlide("next");
        } else if ((lang === "ar" && deltaX > threshold) || (lang === "en" && deltaX < -threshold)) {
            doSlide("prev");
        }
        isSwiping.current = false;
        setScrollDirection(null);
    };

    const doSlide = (nextOrPrev) => {
        if (scrollDirection !== 'vertical') {
            const imagesLength = post?.images_urls?.length - 1;

            if (nextOrPrev === "prev") {
                setSelectedIndex((selectedIndex === 0) ? imagesLength : selectedIndex - 1);
            } else if (nextOrPrev === "next") {
                setSelectedIndex((selectedIndex === imagesLength) ? 0 : selectedIndex + 1);
            } else {
                setSelectedIndex(0);
            }
        }
    }
    useEffect(() => {
        if(!HasImageSlider) return;
        if (selectedIndex === post?.images_urls?.length - 1) {
            setOpenGalleryInPost(true);
        } else {
            setOpenGalleryInPost(false);
        }
    }, [selectedIndex]);
    TheImage = TheImage
    ? TheImage
    : () => (
      <ProgressiveImage
        alt={post?.title ? post?.title : "car"}
        src={thumbUrl(post.image_url, "online-v1", "0x426")}
        loading={isEager ? "eager" : "lazy"}
        fetchpriority={isEager ? "high" : "low"}
        progressiveSmallImage={thumbUrl(
          post?.image_url,
          "online-v1",
          isMobile ? "0x99" : "0x154"
        )}
        width={205}
        height={154}
      />
    );

  return (
    <div className={stl.cardHead} >
      {post?.discount && !isCustomDesign && !hideDiscountLabel ? (
        <DiscountLabel text={post.discount} />
      ) : null}
      {post.campaign_id &&
        !post.limited_quantity &&
        !isCustomDesign &&
        !hideMarkitingLabel ? (
        <MarkitingLabel
          campaign={{
            id: post.campaign_id,
            txt: post.campaign_txt,
            bg_color: post.campaign_bg_color_hex,
          }}
        />
      ) : null }
      {post.is_booked ? (
        <div className={stl.reservedCarTag}>
          <span>{t("محجوزة", { ns: "web_search" })}</span>
        </div>
      ) : null }
      {(post?.images_urls?.length === 1 || !HasImageSlider) &&
        post?.image_url?.length > 0 ? (
        <Tag {...{ [LinkType]: to_link }}>

          <TheImage />

        </Tag>
      ) : (
          <div
              className={stl.carSliderCntainer}
              onTouchStart={!slideOnlyOnArr && handleTouchStart} onTouchMove={!slideOnlyOnArr && handleTouchMove}
              onTouchEnd={!slideOnlyOnArr && handleTouchEnd}
              style={{touchAction: scrollDirection === 'horizontal' ? 'pan-x' : 'pan-y'}}
          >
          {(SHOW_DEBUG_INFO) && (
              /*Print {rank} as absolute liable for debugging purpose, it should be string and with font of 25*/
              <div style={{position: 'relative', fontSize: '50px',width:'70px', color: 'red', zIndex: 1,textAlign:'left'}}>{rank}</div>
          )}
          {(SHOW_DEBUG_INFO && post.uniqueId) && (
              <div style={{position: 'relative', fontSize: '20px',width:'70px', color: 'green', zIndex: 1,textAlign:'left'}}>id: {post.uniqueId.toString().substring(3)}</div>
          )}
          <span
            className={[
              stl.prevArr,
              selectedIndex === 0 ? stl.hideArr : undefined,
            ].join(" ")}
            onClick={() => {
              selectedIndex !== 0 && doSlide("prev");
            }}
          />

          <span
            className={[
              stl.nxtArr,
              selectedIndex === post.images_urls?.length - 1
                ? stl.hideArr
                : undefined,
            ].join(" ")}
            onClick={() => {
              selectedIndex !== post.images_urls?.length - 1 &&
                doSlide("next");
            }}
          />

          {HasImageSlider &&
          <span className={stl.counter}>
            {selectedIndex + 1}/{post?.images_urls?.length}
          </span>}
          <Tag {...{
            [LinkType]: to_link +
            (post.images_urls?.length === selectedIndex + 1 ? `${to_link.includes("#")?"&":"#"}bigGallView=${openGalleryInPost ? 1 : 0}` : "")
          }}
          >
            {post.images_urls?.map((image, idx) => {

              return (
                (idx === 0 || showAllImages)
                && (

                  <ProgressiveImage
                    key={idx}
                    className={[
                      stl.singleItem,
                      selectedIndex === idx ? stl.activeItem : undefined,
                      // FIXME: remove this, it's not used most probably
                      idx === selectedIndex ? "activeSlide" : undefined,
                      idx > selectedIndex ? stl.nextSlide : undefined,
                      idx < selectedIndex ? stl.prevSlide : undefined,
                      selectedIndex === post?.images_urls?.length - 1 && idx === 0
                        ? stl.actAsLst
                        : undefined,
                      idx === post?.images_urls?.length - 1 && selectedIndex === 0
                        ? stl.actAsfirst
                        : undefined,
                    ].join(" ")}
                    src={thumbUrl(
                      image,
                      "online-v1",
                      "0x426"
                    )}
                    alt={post?.title ? post?.title : "car"}
                    loading={idx === 0 && isEager ? "eager" : "lazy"}
                    fetchpriority={isEager ? "high" : "low"}
                    progressiveSmallImage={thumbUrl(
                      post?.image_url,
                      "online-v1",
                      isMobile ? "0x99" : "0x154"
                    )}
                    width={205}
                    height={154}
                  />
                )
              );
            })}
          </Tag>
          {HasImageSlider && post.images_urls?.length === selectedIndex + 1 && (
            <div className={stl.hoverdData}>
              <span>
                  {t("شاهد جميع الصور", { ns: "web_search" })}
              </span>
            </div>
          )}
        </div>
      )}
      {!isCustomDesign && !hideFavIcon && (
        <AddToFavoriteButton
          postId={+(post.uniqueId || post.id)}
          className={stl.FavoriteButton}
          g4_data={post.g4_data_layer}
        />
      )}
    </div>
  );
};

export default CardHeader;
