import { useEffect } from "react";
function useKeyboardDetector(className) {
  useEffect(function () {
    // Function to handle focus and add the class
    var handleFocus = function handleFocus(event) {
      var target = event.target;
      if (target.tagName === "INPUT" || target.tagName === "TEXTAREA") {
        document.body.classList.add(className);
      }
    };

    // Function to handle blur and remove the class
    var handleBlur = function handleBlur() {
      document.body.classList.remove(className);
    };

    // Add event listeners to capture focus and blur events
    document.addEventListener("focusin", handleFocus);
    document.addEventListener("focusout", handleBlur);

    // Cleanup event listeners on unmount
    return function () {
      document.removeEventListener("focusin", handleFocus);
      document.removeEventListener("focusout", handleBlur);
    };
  }, [className]);
}
export default useKeyboardDetector;