import { useState, useEffect } from "react";
import { SECURE_COOKIE } from "@globalConfig";
import useCookieClient from "@hooks/useCookieClient";
var DEFAULT_MAX_USAGES = 3;
var DEFAULT_NUM_DAYS = 1;
var useActionCookie = function useActionCookie(_ref) {
  var cookieName = _ref.cookieName,
    _ref$maxUsages = _ref.maxUsages,
    maxUsages = _ref$maxUsages === void 0 ? DEFAULT_MAX_USAGES : _ref$maxUsages,
    _ref$numDays = _ref.numDays,
    numDays = _ref$numDays === void 0 ? DEFAULT_NUM_DAYS : _ref$numDays;
  var _useState = useState(false),
    canUse = _useState[0],
    setCanUse = _useState[1];
  var _useCookieClient = useCookieClient(),
    getCookieClient = _useCookieClient.getCookieClient,
    setCookieClient = _useCookieClient.setCookieClient;
  useEffect(function () {
    var currentUsage = Number(getCookieClient(cookieName)) || 0;
    if (currentUsage >= maxUsages) {
      setCanUse(false);
    } else {
      setCanUse(true);
    }
  }, [cookieName, maxUsages]);
  var increase = function increase() {
    var currentUsage = Number(getCookieClient(cookieName)) || 0;
    var updatedUsage = currentUsage + 1;
    setCookieClient(cookieName, updatedUsage.toString(), {
      path: "/",
      maxAge: numDays * 24 * 60 * 60,
      sameSite: true,
      secure: SECURE_COOKIE
    });
    setCanUse(updatedUsage < maxUsages);
  };
  return [canUse, increase];
};
export default useActionCookie;

// Example usage:
// const [showDownloadBanner, increaseDownloadBannerUsage] = useActionCookie({ cookieName: "banner_usage", maxUsages: 5, resetDays: 10 });