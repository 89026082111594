import { useEffect, useState } from 'react';
function useKeyPressAndTapSwitch(callback) {
  useEffect(function () {
    // Key press event handler
    var handleKeyPress = function handleKeyPress(event) {
      if (event.key.toLowerCase() === 's' && event.shiftKey) {
        callback();
      }
    };

    // Attach the event listener for key press
    window.addEventListener('keydown', handleKeyPress);

    // Cleanup the event listener on component unmount
    return function () {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [callback]);

  // State to manage two-finger taps
  var _useState = useState(0),
    tapCount = _useState[0],
    setTapCount = _useState[1];
  var _useState2 = useState(null),
    tapStartTime = _useState2[0],
    setTapStartTime = _useState2[1];
  useEffect(function () {
    var handleTouch = function handleTouch(event) {
      // Check if it's a two-finger tap
      if (event.touches.length === 2) {
        var currentTime = Date.now();
        if (!tapStartTime) {
          // Start timing the 5-second window
          setTapStartTime(currentTime);
          setTapCount(1);
        } else if (currentTime - tapStartTime <= 5000) {
          // Increment tap count if within 5 seconds
          setTapCount(function (prev) {
            return prev + 1;
          });
        } else {
          // Reset if outside the 5-second window
          setTapStartTime(currentTime);
          setTapCount(1);
        }
      }
    };

    // Check if the tap count reaches 5
    if (tapCount >= 5) {
      callback();
      setTapCount(0); // Reset the tap count
      setTapStartTime(null); // Reset the timer
    }

    // Attach the event listener for touch events
    window.addEventListener('touchstart', handleTouch);

    // Cleanup the event listener on component unmount
    return function () {
      window.removeEventListener('touchstart', handleTouch);
    };
  }, [callback, tapCount, tapStartTime]);
}
export default useKeyPressAndTapSwitch;