import {handleGtmEvent, events as gtmEvents} from "@/common/events/gtmEvents";
import {handleFreshworkEvent, freshWorksEvents} from "@/common/events/freshworkEvents";
import {checkFeatureFlag} from "@/common/config/websiteConfig";
import {useToast} from "@/common/events/ToastContext";


if (__IS_CSR__) {
    window.lastEvent = null;
}

/**
 * @param {keyof gtmEvents| keyof freshWorksEvents} event
 * @param {object} data
 */
export function trackEvents(event, data) {
    if (window.lastEvent === event) {
        return;
    }
    window.lastEvent = event;
    setTimeout(() => {
        window.lastEvent = null;
    }, 500);

    handleGtmEvent(event, data);
    checkFeatureFlag("FF_ENABLE_FRESHWORKS_EVENT") && handleFreshworkEvent(event, data);
}
